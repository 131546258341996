import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import FormLabel from "nlib/common/FormLabel";
import Input from "nlib/ui/Input";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useState } from "react";
import Tooltip from "nlib/ui/Tooltip";

const EXCLUDED_CHARACTERS_PATTERN = /[.,/\\]+/g;

const CreateFolderWindow = ({ folders, onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [folderName, setFolderName] = useState("");

  const uniqueFolderName = !folders.some(({ name }) => {
    return name && folderName && name.trim().toLowerCase() === folderName.trim().toLowerCase();
  });

  const handleWindowClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCreateButtonClick = useCallback(() => {
    onClose(folderName.trim());
  }, [folderName, onClose]);

  const handleCloseButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleFolderNameInputChange = useCallback((value) => {
    setFolderName(value.replace(EXCLUDED_CHARACTERS_PATTERN, ""));
  }, []);

  return (
    <Modal
      size="sm"
      className={Css.createFolderWindow}
      iconComponent={Icons.FolderPlus}
      title={uiTexts.createFolder}
      onClose={handleWindowClose}>
      <div className={Css.content}>
        <FormLabel>{uiTexts.folder}</FormLabel>
        <Input
          placeholder={uiTexts.enterFolderName}
          value={folderName}
          onChange={handleFolderNameInputChange} />
        <Tooltip opened={!uniqueFolderName}>
          <div className={Css.uniqueFolderNameError}>
            <Icons.Warning />
            <span>{uiTexts.nameIsNotUnique}</span>
          </div>
        </Tooltip>
      </div>
      <div className={Css.actions}>
        <Button
          primary large
          disabled={!folderName.trim() || !uniqueFolderName}
          onClick={handleCreateButtonClick}>
          {uiTexts.create}
        </Button>
        <Button large outline onClick={handleCloseButtonClick}>
          {uiTexts.cancel}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(CreateFolderWindow);
