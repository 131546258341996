import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Avatar from "nlib/ui/Avatar";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";

const { MENTION_TRIGGER_CHAR } = Constants;

const { COMMENT_TYPES: { NOTE } } = DataConstants;

const MENTION_MATCH_PATTERN = new RegExp(`(${MENTION_TRIGGER_CHAR}\\S* \\([^)]*\\))|(${MENTION_TRIGGER_CHAR}\\S*)`);

const Comment = ({ commentData, usersEmails, onUserMention }) => {
  const { createdBy, text, type: commentType } = commentData;

  const { uiTexts } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const containerRef = useRef();

  const { email, fullName } = createdBy || {};

  const handleMentionLinkClick = useCallback(() => {
    onUserMention(`${MENTION_TRIGGER_CHAR}${email + (fullName === email || !fullName ? "" : ` (${fullName})`)}`);
  }, [email, fullName, onUserMention]);

  const partials = useMemo(() => {
    return text
      .split(MENTION_MATCH_PATTERN)
      .filter((part) => !!part)
      .map((part, index) => {
        const key = `${part}-${index}`;

        const hasMention = MENTION_MATCH_PATTERN.test(part) && usersEmails.includes(part.slice(1));

        return (
          <span
            key={key}
            data-mention={hasMention ? part : undefined}
            className={classNames(hasMention && Css.mention)}
            onClick={handleMentionLinkClick}>{part}</span>
        );
      });
  }, [handleMentionLinkClick, text, usersEmails]);

  const createdByCurrentUser = userData.id === createdBy.id;

  const typeNote = commentType === NOTE;

  return (
    <div
      ref={containerRef}
      className={classNames(Css.comment, createdByCurrentUser && Css.currentUser, typeNote && Css.typeNote)}>
      <Avatar
        className={Css.avatar}
        title={fullName || email}
        onClick={email ? handleMentionLinkClick : undefined} />
      <div className={Css.text} data-tooltip={typeNote ? uiTexts.note : undefined}>
        <span>{partials}</span>
      </div>
    </div>
  );
};

export default React.memo(Comment);
