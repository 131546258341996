import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";
import classNames from "classnames";

const ChangeFolderSelector = ({ className, folders, value, onChange }) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return [
      { value: "", label: <><Icons.FolderPlus /><span>{uiTexts.createFolder}</span></> },
      ...folders.map(({ name }) => ({
        value: name,
        label: <><Icons.FolderNotch /><span>{name}</span></>
      }))
    ];
  }, [folders, uiTexts.createFolder]);

  return (
    <div className={classNames(Css.changeFolderSelector, className)}>
      <Select
        placeholder={<><Icons.CaretDoubleRight /><span>{`${uiTexts.moveThemTo}...`}</span></>}
        value={value}
        options={options}
        onChange={onChange} />
    </div>
  );
};

export default React.memo(ChangeFolderSelector);
