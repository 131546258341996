import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";

import { Link, useHistory, useLocation } from "react-router-dom";
import { checkArchiveFetching, getArchiveData } from "selectors/archive";
import { checkIsBusinessUser } from "selectors/user";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ArchiveActions from "actions/ArchiveActions";
import Badge from "nlib/ui/Badge";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import CreateFolderWindow from "./lib/CreateFolderWindow";
import DataConstants from "const/DataConstants";
import Filters from "./lib/Filters";
import HeaderContent from "./lib/HeaderContent";
import NoDataContent from "nlib/common/NoDataContent";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import Pages from "nlib/pages/Pages";
import Preloader from "nlib/ui/Preloader";
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import Sidebar from "./lib/Sidebar";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import VaultTable from "./lib/VaultTable";
import moment from "moment";
import useAvailableWidth from "hooks/useAvailableWidth";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";

const { FOLDER } = DataConstants.ARCHIVE_ATTACHMENT_TYPES;

const SIMPLIFIED_LAYOUT_MAX_WIDTH = 980;

const checkFolderMatch = ([folderPath, path = [], strict = false]) => {
  if (strict) return folderPath.toString() === path.toString();

  return path.toString().indexOf(folderPath.toString()) === 0;
};

const VaultPage = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const history = useHistory();

  const [envVars, setEnvVars] = useEnvVars();

  const { fromDate, toDate, text, type, editItem, editTask } = envVars;

  const [editFileId] = editItem ? editItem.split(".") : [];

  const { uiTexts, messages } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const archiveData = useSelector(getArchiveData);

  const fetchingData = useSelector(checkArchiveFetching);

  const businessUser = useSelector(checkIsBusinessUser);

  const showCommonModal = useShowCommonModal();

  const { containerRef, availableWidth } = useAvailableWidth([editItem, editTask]);

  const [createFolderWindowOpened, setCreateFolderWindowOpened] = useState(false);

  const { restPath: folderPath } = Pages.getCurrentRouteInfo(pathname);

  const editedItemData = Utils.arrayFindById(archiveData, editFileId);

  const archiveFilesData = useMemo(() => {
    return archiveData.filter((item) => item.type !== FOLDER);
  }, [archiveData]);

  const currentFolderData = useMemo(() => {
    return archiveFilesData
      .filter((item) => checkFolderMatch([folderPath, item.path, true]))
      .filter((item) => {
        return (!fromDate && !toDate) || (moment.utc(item.fromDate) >= moment.utc(fromDate)
        && moment.utc(item.toDate) <= moment.utc(toDate));
      })
      .filter(({ description, tags, attachment: { originalName } = {} }) => {
        return !text || [description, originalName, ...tags]
          .some((value) => value && `${value}`.match(new RegExp(text.trim(), "i")));
      })
      .filter((item) => {
        return !type || type === item.type;
      });
  }, [archiveFilesData, folderPath, fromDate, text, toDate, type]);

  const currentFolderFolders = useMemo(() => {
    return archiveData.reduce((aggregator, { path = [], type: fileType }) => {
      if (path.length > folderPath.length) {
        if (checkFolderMatch([folderPath, path])) {
          const [folder] = path.slice(folderPath.length);

          let folderData = aggregator.find(({ name }) => name === folder);

          if (!folderData) {
            folderData = { name: folder, filesCount: 0 };
            aggregator.push(folderData);
          }

          if (fileType !== FOLDER) folderData.filesCount++;
        }
      }

      return aggregator;
    }, folderPath.length ? [{ name: Constants.PARENT_FOLDER_RELATIVE_PATH }] : [])
      .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));
  }, [archiveData, folderPath]);

  const { route, restPath } = useMemo(() => {
    return Pages.getCurrentRouteInfo(pathname);
  }, [pathname]);

  const { titleLangId } = Pages.getPageData(route, businessUser);

  const title = useMemo(() => {
    return [uiTexts[titleLangId], ...restPath].map((segment, index, array) => {
      const activeSegment = index === array.length - 1;

      const segmentPath = [route, ...restPath].slice(0, index + 1);

      const path = segmentPath.join("/");

      return (
        <Fragment key={path}>
          {array.length === 1 || activeSegment
            ? <span disabled={array.length > 1}>{segment}</span>
            : <Link to={`/${selectedBusinessId + path}`}>{segment}</Link>}
          {!activeSegment && (
            <span>
              <Icons.CaretRight weight="bold" />
            </span>
          )}
        </Fragment>
      );
    });
  }, [restPath, route, selectedBusinessId, titleLangId, uiTexts]);

  const getFolderPath = useCallback((folder) => {
    if (folder === Constants.PARENT_FOLDER_RELATIVE_PATH) return folderPath.slice(0, folderPath.length - 1);

    return [...folderPath, folder];
  }, [folderPath]);

  const handleFolderButtonClick = useCallback(({ currentTarget: { dataset: { folder } } }) => {
    history.push(`/${selectedBusinessId}${UiRoutes.ARCHIVE}/${getFolderPath(folder).join("/")}`);
  }, [getFolderPath, history, selectedBusinessId]);

  const handleCreateFolder = useCallback((foldername) => {
    dispatch(ArchiveActions.createFolder({ path: getFolderPath(foldername) }));
  }, [dispatch, getFolderPath]);

  const handleUploadFiles = useCallback(() => {
    setEnvVars({ editItem: Constants.NEW_ENTITY_ID, editTask: null });
  }, [setEnvVars]);

  const handleUploadFilesButtonClick = useCallback(() => {
    handleUploadFiles();
  }, [handleUploadFiles]);

  const handleSideBarClose = useCallback(async(result) => {
    setEnvVars({ editItem: null });

    if (result) {
      const { files, id, ...restResult } = result;

      if (result.id) {
        dispatch(ArchiveActions.editFile(id, restResult));
      } else {
        const uploadResult = await dispatch(ArchiveActions.uploadFiles(files, { ...restResult, path: folderPath }, true));

        if (uploadResult) dispatch(ArchiveActions.fetchFilesList(false, true));
      }
    }
  }, [setEnvVars, folderPath, dispatch]);

  const handleCreateFolderButtonClick = useCallback(() => {
    setCreateFolderWindowOpened(true);
  }, []);

  const handleCreateFolderWindowClose = useCallback((result) => {
    if (result) handleCreateFolder(result);
    setCreateFolderWindowOpened(false);
  }, [handleCreateFolder]);

  const handleDeleteClick = useCallback(async(event) => {
    event.stopPropagation();

    const { currentTarget: { dataset: { folder } } } = event;

    const ids = archiveData.filter(({ path = [] }) => checkFolderMatch([[...folderPath, folder], path]))
      .map(({ id }) => id);

    const result = await showCommonModal({
      text: Utils.replaceTextVars(messages.folderDelete, { name: folder }),
      confirm: true
    });

    if (result) dispatch(ArchiveActions.bulkFilesUpdate(ids));
  }, [archiveData, dispatch, folderPath, messages, showCommonModal]);

  useLayoutEffect(() => {
    dispatch(ArchiveActions.fetchFilesList(true, false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
  }, [dispatch, fromDate, toDate, text, type, envVars.page, envVars.pageSize]);

  return (
    <>
      <Page className={Css.vaultPage} scrollTop={envVars.page}>
        <PageContent ref={containerRef}>
          <PageHeader
            className={Css.pageHeader}
            title={title}>
            {!!archiveData.length && (
              <HeaderContent
                folders={currentFolderFolders}
                onCreateFolder={handleCreateFolder}
                onUploadFiles={handleUploadFiles} />
            )}
          </PageHeader>
          <Filters />
          {!!currentFolderFolders.length && (
            <div className={Css.foldersRow}>
              {currentFolderFolders.map(({ name, filesCount }) => {
                return (
                  <Button
                    outline
                    key={name}
                    data-folder={name}
                    className={Css.button}
                    disabled={fetchingData}
                    onClick={handleFolderButtonClick}>
                    <div>
                      {name === Constants.PARENT_FOLDER_RELATIVE_PATH
                        ? <>
                          <Icons.ArrowLeft />
                          <span className={Css.folderName}>{uiTexts.back}</span>
                        </>
                        : (
                          <>
                            <Icons.FolderNotch />
                            <span className={Css.folderName}>{name}</span>
                            <Badge counter className={Css.folderFilesCount} value={filesCount} />
                            {(!businessUser || !filesCount) && (
                              <span className={Css.delete} data-folder={name} onClick={handleDeleteClick}>
                                <Icons.Trash />
                              </span>
                            )}
                          </>
                        )}
                    </div>
                  </Button>
                );
              })}
            </div>
          )}
          {currentFolderData.length
            ? (
              <VaultTable
                simplifyLayout={availableWidth <= SIMPLIFIED_LAYOUT_MAX_WIDTH}
                folders={currentFolderFolders}
                data={currentFolderData} />
            )
            : (
              <div className={Css.emptyState}>
                {fetchingData ? <Preloader /> : (
                  <NoDataContent
                    title={(
                      currentFolderFolders.length
                        ? Utils.replaceTextVars(uiTexts.noFilesInFolder, {
                          folderName: folderPath[folderPath.length - 1] || uiTexts.root.toLowerCase()
                        })
                        : uiTexts.noFiles
                    )}>
                    {!archiveData.length && (
                      <div className={Css.buttons}>
                        <Button
                          large outline
                          icon={Icons.FolderNotchPlus}
                          onClick={handleCreateFolderButtonClick}>
                          {uiTexts.createFolder}
                        </Button>
                        <Button
                          primary large
                          icon={Icons.UploadSimple}
                          onClick={handleUploadFilesButtonClick}>
                          {uiTexts.uploadFiles}
                        </Button>
                      </div>
                    )}
                  </NoDataContent>
                )}
              </div>
            )}
        </PageContent>
      </Page>
      {(editItem === Constants.NEW_ENTITY_ID || editedItemData) && (
        <Sidebar
          key={editItem}
          editData={editedItemData}
          onClose={handleSideBarClose} />
      )}
      {createFolderWindowOpened && (
        <CreateFolderWindow
          folders={currentFolderFolders}
          onClose={handleCreateFolderWindowClose} />
      )}
    </>
  );
};

export default React.memo(VaultPage);
