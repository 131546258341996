import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUploadingArchiveFilesCount } from "selectors/archive";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import CreateFolderForm from "../CreateFolderForm";
import React, { useCallback } from "react";
import useEnvVars from "hooks/useEnvVars";

const HeaderContent = ({ folders, onCreateFolder, onUploadFiles }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [envVars] = useEnvVars();

  const archiveFilesCurrentUploadCount = useSelector(getUploadingArchiveFilesCount);

  const handleUploadFilesButtonClick = useCallback(() => {
    onUploadFiles();
  }, [onUploadFiles]);

  return (
    <div className={Css.headerContent}>
      <CreateFolderForm
        className={Css.createFolderForm}
        folders={folders}
        onCreateFolder={onCreateFolder}>
        {({ onClick }) => (
          <>
            <Button
              large outline
              className={Css.button}
              icon={Icons.FolderNotchPlus}
              onClick={onClick}>
              {uiTexts.createFolder}
            </Button>
            {(envVars.editItem !== Constants.NEW_ENTITY_ID) && (
              <Button
                primary large
                className={Css.button}
                data-loading={archiveFilesCurrentUploadCount ? "" : undefined}
                icon={archiveFilesCurrentUploadCount ? Icons.Spinner : Icons.UploadSimple}
                disabled={!!archiveFilesCurrentUploadCount}
                onClick={handleUploadFilesButtonClick}>
                {uiTexts.uploadFiles}
              </Button>
            )}
          </>
        )}
      </CreateFolderForm>
    </div>
  );
};

export default React.memo(HeaderContent);
