import Css from "./style.module.scss";

import React from "react";

const PageContent = ({ children, className, ...restProps }, ref) => {
  return (
    <div className={Css.pageContent} ref={ref} {...restProps}>
      {children}
    </div>
  );
};

export default React.memo(React.forwardRef(PageContent));
