import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DatePickerLocalized from "nlib/common/DatePickerLocalized";
import Input from "nlib/ui/Input";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { DATE_FORMATS } = Constants;

const PERIOD_TODAY = "today";

const PERIOD_WEEK = "week";

const PERIOD_MONTH = "twoWeek";

const PERIOD_QUARTER = "quarter";

const PERIOD_THIS_YEAR = "thisYear";

const PERIOD_TWO_WEEK = "twoWeek";

const PERIOD_WEEK_DAYS = 7;

const PERIOD_TWO_WEEK_DAYS = 14;

const PERIOD_MONTH_DAYS = 30;

const PERIOD_QUARTER_DAYS = 90;

const PERIODS = {
  [PERIOD_TODAY]: (momentObject) => {
    return momentObject
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_WEEK]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_WEEK_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_TWO_WEEK]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_TWO_WEEK_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_MONTH]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_MONTH_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_QUARTER]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_QUARTER_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_THIS_YEAR]: (momentObject) => {
    return momentObject
      .set("month", 0)
      .set("date", 1)
      .format(Constants.DATETIME_FORMATS.API_DATE);
  }
};

const REGEXES = [
  /(?<d>\d{2})[.,:; -](?<m>\d{2})[.,:; -](?<y>\d{4})/,
  /(?<y>\d{4})[.,:; -](?<m>\d{2})[.,:; -](?<d>\d{2})/,
  /(?<m>\d{2})\/(?<d>\d{2})\/(?<y>\d{4})/
];

const SPLIT_REGEX = /\s+[:;/-]\s+/;

const getDateStringFromMatch = (match) => {
  const { y: yy, m: mm, d: dd } = match.groups;

  return `${yy}-${mm}-${dd}`;
};

const parseInputValue = (value) => {
  const [startDate, endDate] = value.split(SPLIT_REGEX);

  if (!startDate || !endDate) return null;

  const startMatch = REGEXES.map((regex) => startDate.match(regex)).find(Boolean);

  const endMatch = REGEXES.map((regex) => endDate.match(regex)).find(Boolean);

  if (!startMatch || !endMatch) return null;

  const results = [startMatch, endMatch].map((el) => getDateStringFromMatch(el));

  return results.every((str) => Date.parse(str)) ? results : null;
};

const dateFormatter = (dates, dateFormat) => {
  return dates.filter(Boolean).map((str) => {
    return moment(str).format(dateFormat);
  }).join(" - ");
};

const handleInputFocus = (event) => {
  event.currentTarget.select();
};

const DateRangePickerExtended = (props) => {
  const {
    className,
    value,
    label,
    active,
    placeholder,
    iconBefore,
    onChange,
    ...restProps
  } = props;

  const activeOrganization = useSelector(getActiveOrganization);

  const { uiTexts } = useSelector(getTextsData);

  const [localValue, setLocalValue] = useState(value);

  const [opened, setOpened] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const dateFormat = activeOrganization.countryCode === Countries.US ? DATE_FORMATS.US : DATE_FORMATS.EU;

  const hasValue = !!value.filter(Boolean).length;

  const setInputValueFormatted = useCallback((str) => {
    setInputValue(dateFormatter(str, dateFormat));
  }, [dateFormat]);

  const handleInputChange = useCallback((newValue) => {
    setInputValue(newValue);
    if (!newValue) {
      onChange("");

      return;
    }

    const parsed = parseInputValue(newValue);

    if (parsed) {
      onChange(parsed);
      setOpened(false);
    }
  }, [onChange, setOpened]);

  const handleClearClick = useCallback((event) => {
    event.stopPropagation();
    onChange([]);
  }, [onChange]);

  const toggleButton = useMemo(() => {
    return (
      <>
        <Input
          active={active}
          placeholder={placeholder}
          value={inputValue}
          className={Css.input}
          iconBefore={Icons.CalendarBlank}
          onFocus={handleInputFocus}
          onChange={handleInputChange} />
        {hasValue && (
          <span
            className={Css.clear}
            onClick={handleClearClick}>
            <Icons.X />
          </span>
        )}
      </>
    );
  }, [
    active,
    hasValue,
    inputValue,
    placeholder,
    handleClearClick,
    handleInputChange
  ]);

  const handlePeriodButtonClick = useCallback((event, name) => {
    const momentObject = moment();

    const endDateString = momentObject.format(Constants.DATETIME_FORMATS.API_DATE);

    onChange([PERIODS[name](momentObject), endDateString]);
    setOpened(false);
  }, [onChange]);

  const handleChange = useCallback((newValue) => {
    setLocalValue(newValue);

    const [startDate, endDate] = newValue;

    if (startDate && !endDate) return;

    onChange(newValue);
  }, [onChange]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    setInputValueFormatted(value);
  }, [value, setInputValueFormatted]);

  return (
    <DatePickerLocalized
      range portal
      {...restProps}
      value={localValue}
      opened={opened}
      toggleButton={toggleButton}
      className={classNames(Css.dateRangePickerExtended, className)}
      dropDownClassName={Css.dateRangePickerDropDown}
      onChange={handleChange}
      setOpened={setOpened}>
      {({ after }) => {
        if (!after) return null;

        return (
          <div className={Css.extension}>
            <Button
              light
              value={PERIOD_TODAY}
              className={Css.button}
              onClick={handlePeriodButtonClick}>
              {uiTexts.today}
            </Button>
            <Button
              light
              value={PERIOD_WEEK}
              className={Css.button}
              onClick={handlePeriodButtonClick}>
              {Utils.replaceTextVars(uiTexts.lastCountDays, { days: "7" })}
            </Button>
            <Button
              light
              value={PERIOD_TWO_WEEK}
              className={Css.button}
              onClick={handlePeriodButtonClick}>
              {Utils.replaceTextVars(uiTexts.lastCountDays, { days: "14" })}
            </Button>
            <Button
              light
              value={PERIOD_MONTH}
              className={Css.button}
              onClick={handlePeriodButtonClick}>
              {Utils.replaceTextVars(uiTexts.lastCountDays, { days: "30" })}
            </Button>
            <Button
              light
              value={PERIOD_QUARTER}
              className={Css.button}
              onClick={handlePeriodButtonClick}>
              {Utils.replaceTextVars(uiTexts.lastCountDays, { days: "90" })}
            </Button>
            <Button
              light
              value={PERIOD_THIS_YEAR}
              className={Css.button}
              onClick={handlePeriodButtonClick}>
              {uiTexts.thisYear}
            </Button>
          </div>
        );
      }}
    </DatePickerLocalized>
  );
};

export default React.memo(DateRangePickerExtended);
