import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getCommentsData } from "selectors/comments";
import { getSelectedBusinessUsersData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { usePreviousValue } from "hooks";
import AddCommentField from "nlib/common/AddCommentField";
import Comment from "./lib/Comment";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const { COMMENT_TARGET_TYPES: { AUDIT } } = DataConstants;

let messageDraft = "";

const CommentsBlock = (props) => {
  const {
    popup,
    compact,
    className,
    commentTarget,
    commentTargetId,
    comments: {
      all: allCommentsCount = 0,
      unread: unreadCommentsCount = 0
    } = {},
    onInputFocus,
    onClose,
    onAskClient
  } = props;

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const commentsData = useSelector(getCommentsData);

  const usersData = useSelector(getSelectedBusinessUsersData);

  const commentsRef = useRef();

  const inputRef = useRef();

  const [dataLoaded, setDataLoaded] = useState(false);

  const [comment, setComment] = useState(messageDraft);

  const previousAllCommentsCount = usePreviousValue(allCommentsCount);

  const previousUnreadCommentsCount = usePreviousValue(unreadCommentsCount);

  const usersEmails = useMemo(() => {
    return usersData.map(({ email, fullName }) => email + (fullName === email ? "" : ` (${fullName})`));
  }, [usersData]);

  const fetchComments = useCallback((clearList) => {
    return dispatch(CommentsActions.fetchComments(commentTarget, commentTargetId, clearList));
  }, [commentTarget, commentTargetId, dispatch]);

  const handleUserMention = useCallback((mention) => {
    setComment((prev) => {
      const text = prev.trim();

      messageDraft = `${text ? `${text} ` : ""}${mention} `;

      return messageDraft;
    });
    inputRef.current.focus();
  }, []);

  const handleCommentChange = useCallback((value) => {
    messageDraft = value;
    setComment(value);
  }, []);

  useLayoutEffect(() => {
    fetchComments(true).then(() => setDataLoaded(true));

    return () => {
      dispatch(CommentsActions.fetchUnreadComments());
    };
  }, [fetchComments, dispatch]);

  useEffect(() => {
    if (unreadCommentsCount !== previousUnreadCommentsCount && unreadCommentsCount > 0) {
      fetchComments();
    }
  }, [unreadCommentsCount, previousUnreadCommentsCount, fetchComments]);

  useEffect(() => {
    if (allCommentsCount < previousAllCommentsCount) {
      fetchComments();
    }
  }, [allCommentsCount, previousAllCommentsCount, fetchComments]);

  useEffect(() => {
    const el = commentsRef.current;

    el.scrollTop = el.scrollHeight;
  }, [dataLoaded, commentsData]);

  return (
    <div className={classNames(Css.commentsBlock, compact && Css.compact, className)}>
      {popup && (
        <div className={Css.header}>
          <div className={Css.title}>{uiTexts.comments}</div>
          <div className={Css.icon} onClick={onClose}>
            <Icons.X weight="bold" />
          </div>
        </div>
      )}
      <div className={Css.contentWrap}>
        <div className={Css.content} ref={commentsRef}>
          {commentsData.length
            ? (
              <div className={Css.comments}>
                {commentsData.map((commentData, index) => {
                  const prevCommentData = index ? commentsData[index - 1] : null;

                  return (
                    <Comment
                      key={commentData.id}
                      prevCommentSameUser={prevCommentData
                      && prevCommentData.createdBy.id === commentData.createdBy.id}
                      usersEmails={usersEmails}
                      commentData={commentData}
                      onUserMention={handleUserMention} />
                  );
                })}
              </div>
            )
            : (
              dataLoaded
                ? <div className={Css.emptyState}><Icons.ChatText /></div>
                : <Preloader className={Css.preloader} />
            )}
        </div>
      </div>
      <div className={Css.addCommentWrap}>
        <AddCommentField
          popupMode
          commentsLoaded={dataLoaded}
          autoHeight={false}
          portal={popup}
          audit={commentTarget === AUDIT}
          inputRef={inputRef}
          compact={compact}
          value={comment}
          onAskClient={onAskClient}
          onFocus={onInputFocus}
          onChange={handleCommentChange} />
      </div>
    </div>
  );
};

export default React.memo(CommentsBlock);
