import Css from "./style.module.scss";

import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ActionCellContent from "nlib/common/ActionCellContent";
import ArchiveActions from "actions/ArchiveActions";
import React, { useCallback, useMemo } from "react";
import useShowCommonModal from "hooks/useShowCommonModal";

const stopPropagation = (event) => event.stopPropagation();

const ActionsCell = (props) => {
  const {
    itemId,
    attachment,
    onEdit
  } = props;

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const businessUser = useSelector(checkIsBusinessUser);

  const { messages } = useSelector(getTextsData);

  const menuItems = useMemo(() => {
    return [
      { action: "download" },
      { action: "edit" },
      !businessUser && { action: "delete" }
    ].filter(Boolean);
  }, [businessUser]);

  const handleMenuItemClick = useCallback(async(action) => {
    switch (action) {
      case "edit":
        onEdit(itemId);
        break;

      case "delete": {
        const result = await showCommonModal({
          confirm: true,
          text: messages.fileDeleteConfirm
        });

        if (result) dispatch(ArchiveActions.deleteFile(itemId));
        break;
      }
      case "download": {
        await dispatch(ArchiveActions.downloadFile(itemId, attachment));
        break;
      }
      default:
        break;
    }
  }, [attachment, dispatch, itemId, messages, onEdit, showCommonModal]);

  return (
    <div className={Css.actionsCell} onClick={stopPropagation}>
      <ActionCellContent
        menuItems={menuItems}
        onClick={handleMenuItemClick} />
    </div>
  );
};

export default React.memo(ActionsCell);
