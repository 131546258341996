import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { getOpenedCommentsEntityId } from "selectors/ui";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import CommentsBlock from "nlib/common/CommentsBlock";
import DataConstants from "const/DataConstants";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import DropDownToggle from "nlib/ui/DropDown/lib/DropDownToggle";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";

const { COMMENT_TARGET_TYPES: { ARCHIVE } } = DataConstants;

const stopPropagation = (event) => event.stopPropagation();

const CommentCell = ({ id, comments = {} }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const { all: allCommentsCount = 0, unread: unreadCommentsCount } = comments;

  const dropdownOpened = useSelector(getOpenedCommentsEntityId) === id;

  const handleShowMoreClick = useCallback(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(id));
  }, [dispatch, id]);

  const handleCloseClick = useCallback(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
  }, [dispatch]);

  return (
    <div className={Css.commentCell} onClick={stopPropagation}>
      <DropDown opened={dropdownOpened}>
        <DropDownToggle
          className={Css.toggle}
          title={uiTexts.addComment}
          onClick={handleShowMoreClick}>
          <Icons.ChatText weight={dropdownOpened ? "fill" : "regular"} />
          <Badge
            counter
            theme={unreadCommentsCount ? "attention" : null}>
            {allCommentsCount}
          </Badge>
        </DropDownToggle>
        <DropDownContent alignRight className={Css.dropdownContent}>
          <CommentsBlock
            popup
            className={Css.commentsBlock}
            commentTarget={ARCHIVE}
            commentTargetId={id}
            comments={comments}
            onClose={handleCloseClick} />
        </DropDownContent>
      </DropDown>
    </div>
  );
};

export default React.memo(CommentCell);
