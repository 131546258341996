import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import DateRangePickerExtended from "nlib/common/DateRangePickerExtended";
import DebounceInput from "nlib/ui/DebounceInput";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Row from "nlib/ui/Row";
import Select from "nlib/ui/Select";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const {
  ARCHIVE_ATTACHMENT_TYPES: { TAX_STATEMENT, AGREEMENT, BANK_STATEMENT, OTHER }
} = DataConstants;

const Filters = ({ compact }) => {
  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const {
    text = "",
    fromDate,
    toDate,
    type
  } = envVars;

  const [textInputValue, setTextInputValue] = useState(text);

  const fromToDate = useMemo(() => [fromDate, toDate], [fromDate, toDate]);

  const filtersActive = [text, fromDate, toDate, type].find(Boolean);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    { value: AGREEMENT, label: uiTexts.agreement },
    { value: TAX_STATEMENT, label: uiTexts.taxStatement },
    { value: BANK_STATEMENT, label: uiTexts.bankStatement },
    { value: OTHER, label: uiTexts.other }
  ], [type, uiTexts]);

  const handleTextInputComplete = useCallback((value) => {
    setEnvVars({ text: value || null });
  }, [setEnvVars]);

  const handleDateChange = useCallback((value) => {
    const [from, to] = value;

    setEnvVars({ fromDate: from, toDate: to });
  }, [setEnvVars]);

  const handleTypeChange = useCallback((value) => {
    setEnvVars({ type: value });
  }, [setEnvVars]);

  const handleResetButtonClick = useCallback(() => {
    setEnvVars({ text: null, fromDate: null, toDate: null, type: null });
  }, [setEnvVars]);

  useEffect(() => {
    setTextInputValue(text || "");
  }, [text]);

  return (
    <div className={classNames(Css.filters, filtersActive && Css.active)}>
      <Row>
        <DebounceInput
          cleanable
          active={!!text}
          className={Css.search}
          placeholder={uiTexts.searchText}
          value={textInputValue}
          onChange={setTextInputValue}
          onInputComplete={handleTextInputComplete}
          iconBefore={Icons.MagnifyingGlass} />

        {!compact && (
          <>
            <DateRangePickerExtended
              range
              active={!!fromDate && !!toDate}
              value={fromToDate}
              className={Css.date}
              placeholder={uiTexts.selectDate}
              onChange={handleDateChange} />

            <Select
              active={!!type}
              className={Css.type}
              placeholder={uiTexts.selectType}
              iconBefore={Icons.File}
              value={type}
              options={typeOptions}
              onChange={handleTypeChange} />

            <Button
              large outline danger
              className={classNames(Css.resetButton, filtersActive && Css.active)}
              icon={Icons.X}
              onClick={handleResetButtonClick}>
              {uiTexts.resetFilters}
            </Button>
          </>
        )}
      </Row>
    </div>
  );
};

export default React.memo(Filters);
