import Css from "./style.module.scss";

import GlobalNotifications from "nlib/common/GlobalNotifications";
import React, { memo, useEffect, useRef } from "react";
import classNames from "classnames";

const Page = memo(({ children, className, scrollTop, pageRef, ...restProps }) => {
  const innerRef = useRef();

  const ref = pageRef || innerRef;

  useEffect(() => {
    ref.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [ref, scrollTop]);

  return (
    <div className={classNames(Css.page, "scroll-container", className)} ref={ref} {...restProps}>
      <GlobalNotifications />
      {children}
    </div>
  );
});

export default React.memo(Page);
