import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const Card = ({ className, children, ...restProps }, ref) => {
  return (
    <div ref={ref} className={classNames(Css.card, className)} {...restProps}>
      {children}
    </div>
  );
};

export default React.memo(React.forwardRef(Card));
