import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { AutoTooltip, Modal } from "nlib/ui";
import { getLinkExpirationsInfoData } from "selectors/institutions";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActionCellContent from "nlib/common/ActionCellContent";
import Constants from "const/Constants";
import React, { useCallback } from "react";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import classNames from "classnames";
import moment from "moment";

const ReconnectAccountModal = ({ onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const linkExpirationsInfoData = useSelector(getLinkExpirationsInfoData);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleMenuItemClick = useCallback((action, { currentTarget: { dataset: { id } } }) => {
    switch (action) {
      case "reconnect":
        onClose(id);
        break;

      default:
        break;
    }
  }, [onClose]);

  return (
    <Modal
      className={Css.reconnectAccountModal}
      title={uiTexts.bankConnectionIssues}
      dialogClassName={Css.dialogClassName}
      iconComponent={Icons.CreditCard}
      onClose={handleClose}>
      <p>
        <Icons.Warning className={CommonCss.warningText} />
        {messages.institutionsReconnectWindowMessage}
      </p>
      <Table theme="flat">
        <TableRow>
          <TableHead className={Css.statusCell}>{uiTexts.status}</TableHead>
          <TableHead className={Css.businessNameCell}>{uiTexts.businessName}</TableHead>
          <TableHead className={Css.bankNameCell}>{uiTexts.bankName}</TableHead>
          <TableHead className={Css.actionCell}>{uiTexts.actions}</TableHead>
        </TableRow>
        {linkExpirationsInfoData.map((data) => {
          const { linkId, expired, disconnected, institutionName, businessName, expiresAt } = data;

          return (
            <TableRow key={linkId}>
              <TableCell className={Css.statusCell}>
                <div className={classNames(Css.text, (disconnected || expired) && Css.negative)}>
                  {expired ? uiTexts.expired.toUpperCase()
                    : (disconnected ? uiTexts.disconnected.toUpperCase()
                      : `${uiTexts.expires}: ${moment.utc(expiresAt).format(Constants.DATETIME_FORMATS.DATE_TEXT)}`)}
                </div>
              </TableCell>
              <TableCell className={Css.businessNameCell}>
                <AutoTooltip>{businessName}</AutoTooltip>
              </TableCell>
              <TableCell className={Css.bankNameCell}>
                <AutoTooltip>{institutionName}</AutoTooltip>
              </TableCell>
              <TableCell className={Css.actionCell}>
                <ActionCellContent
                  menuItems={[
                    {
                      "data-id": linkId,
                      icon: Icons.ArrowsClockwise,
                      action: "reconnect",
                      theme: (expired || disconnected) ? "danger" : "warning",
                      text: expired || disconnected ? uiTexts.reconnect : uiTexts.renew
                    }
                  ]}
                  onClick={handleMenuItemClick} />
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </Modal>
  );
};

export default React.memo(ReconnectAccountModal);
