import { useCallback, useEffect, useRef, useState } from "react";
import useWindowResize from "./useWindowResize";

const useAvailableWidth = (dependencies = []) => {
  const containerRef = useRef();

  const [availableWidth, setAvailableWidth] = useState(0);

  const handleWindowResize = useCallback(() => {
    if (containerRef.current) {
      setAvailableWidth(containerRef.current.clientWidth);
    } else {
      setAvailableWidth(0);
    }
  }, []);

  useWindowResize(handleWindowResize);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleWindowResize(), [handleWindowResize, ...dependencies]);

  return { containerRef, availableWidth };
};

export default useAvailableWidth;
