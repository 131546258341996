import Constants from "const/Constants";
import Css from "./style.module.scss";

import { Avatar, Tooltip } from "nlib/ui";
import React, { useCallback, useState } from "react";
import moment from "moment";

const UploadedByCell = ({ createdBy, createdAt }) => {
  const { fullName, email } = createdBy || {};

  const createdAtText = createdAt ? moment(createdAt).format(Constants.DATETIME_FORMATS.DATETIME_TEXT) : null;

  const [tooltipOpened, setTooltipOpened] = useState(false);

  const handleTooltipOpen = useCallback(() => setTooltipOpened(true), []);

  const handleTooltipClose = useCallback(() => setTooltipOpened(false), []);

  return (
    <div
      className={Css.uploadedByCell}
      onMouseEnter={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}>
      <Avatar title={fullName || email} size="small" />
      <Tooltip
        className={Css.tooltip}
        opened={tooltipOpened}>
        <div>{fullName || email}</div>
        {createdAtText && <div>{createdAtText}</div>}
      </Tooltip>
    </div>
  );
};

export default React.memo(UploadedByCell);
