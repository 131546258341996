import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getGlobalStats } from "selectors/businesses";
import { getProjectName } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Pages from "nlib/pages/Pages";
import React, { useEffect, useMemo } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const PageHeader = ({ title, iconComponent: propsIconComponent, subtitle, children, className, ...restProps }) => {
  const { pathname } = useLocation();

  const { uiTexts } = useSelector(getTextsData);

  const projectName = useSelector(getProjectName);

  const businessUser = useSelector(checkIsBusinessUser);

  const globalStatsData = useSelector(getGlobalStats);

  const { route } = useMemo(() => {
    return Pages.getCurrentRouteInfo(pathname);
  }, [pathname]);

  const { title: pageTitle = title, iconComponent, statsNotificationProp: statsProp } = useMemo(() => {
    const { titleLangId, iconComponent: icon, statsNotificationProp } = Pages.getPageData(route, businessUser) || {};

    return { title: uiTexts[titleLangId], iconComponent: icon, statsNotificationProp };
  }, [route, uiTexts, businessUser]);

  const IconComponent = propsIconComponent || iconComponent || Icons.Info;

  const statsNotification = Utils.getPropertyByPath(globalStatsData, statsProp);

  useEffect(() => {
    window.document.title = `${statsNotification ? `(${statsNotification}) ` : ""}${pageTitle} | ${projectName}`;
  }, [projectName, pageTitle, statsNotification]);

  return (
    <div className={classNames(Css.pageHeader, className)} {...restProps}>
      <div className={Css.leftBlock}>
        <div className={Css.title}>
          <IconComponent />
          <span>{title || pageTitle}</span>
        </div>
        {!!subtitle && <div className={Css.subtitle}>{subtitle}</div>}
      </div>
      <div className={Css.rightBlock}>{children}</div>
    </div>
  );
};

export default React.memo(PageHeader);
